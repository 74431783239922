export const initialValues = {
  accessAllReservations: false,
  accessContactData: false,
  accessFinancialData: false,
  accessGuestNotes: false,
  accessTagManagement: false,
  address: "",
  city: "",
  country: "",
  createAutomation: false,
  createBookingEngine: false,
  createBulkEditListings: false,
  createCalendar: false,
  createChannelManager: false,
  createExpense: false,
  createFinancialReporting: false,
  createGuestInvoicing: false,
  createGuestbook: false,
  createIntegration: false,
  createListing: false,
  createMessage: false,
  createOwnerStatement: false,
  createOwnerStay: false,
  createReservation: false,
  createReview: false,
  createTask: false,
  deleteAutomation: false,
  deleteBookingEngine: false,
  deleteBulkEditListings: false,
  deleteCalendar: false,
  deleteChannelManager: false,
  deleteExpense: false,
  deleteFinancialReporting: false,
  deleteGuestInvoicing: false,
  deleteGuestbook: false,
  deleteIntegration: false,
  deleteListing: false,
  deleteMessage: false,
  deleteOwnerStatement: false,
  deleteOwnerStay: false,
  deleteReservation: false,
  deleteReview: false,
  deleteTask: false,
  email: "",
  emergencyPhone: "",
  firstName: "",
  formulaNameToShowTotalPriceForm: "",
  hasAccessToNewListings: false,
  isAdmin: false,
  lastName: "",
  noteBeforeContact: "",
  password: "",
  phone: "",
  postalCode: "",
  preferredContactMethod: "",
  readAutomation: false,
  readBookingEngine: false,
  readBulkEditListings: false,
  readCalendar: false,
  readCleaning: false,
  readViews: false,
  readChannelManager: false,
  readExpense: false,
  readFinancialReporting: false,
  readGuestInvoicing: false,
  readGuestbook: false,
  readIntegration: false,
  readListing: false,
  readMessage: false,
  readOwnerStatement: false,
  readOwnerStay: false,
  readReservation: false,
  readReview: false,
  readTask: false,
  template: "",
  timezone: "",
  title: "",
  updateAutomation: false,
  updateBookingEngine: false,
  updateBulkEditListings: false,
  updateCleaning: false,
  updateCalendar: false,
  updateChannelManager: false,
  updateExpense: false,
  updateFinancialReporting: false,
  updateGuestInvoicing: false,
  updateGuestbook: false,
  updateIntegration: false,
  updateListing: false,
  updateMessage: false,
  updateOwnerStatement: false,
  updateOwnerStay: false,
  updateReservation: false,
  updateReview: false,
  updateTask: false,
  userGroupId: "",
  assignedCity: [],
  readLockBox: false,
  createLockBox: false,
  updateLockBox: false,
  deleteLockBox: false,
  readKeys: false,

  notifications: {
    airbnbAlterationRequests: {
      dashboard: false,
      email: false,
      mobileApp: false,
    },
    errorReports: {
      dashboard: false,
      email: false,
      mobileApp: false,
    },
    export: {
      success: {
        dashboard: false,
        email: false,
        mobileApp: false,
      },
      fail: {
        dashboard: false,
        email: false,
        mobileApp: false,
      },
    },
    inquiry: {
      new: {
        dashboard: false,
        email: false,
        mobileApp: false,
      },
    },
    listingImport: {
      success: {
        dashboard: false,
        email: false,
        mobileApp: false,
      },
      fail: {
        dashboard: false,
        email: false,
        mobileApp: false,
      },
      listingImport: {
        dashboard: false,
        email: false,
        mobileApp: false,
      },
    },
    message: {
      receive: {
        dashboard: false,
        email: false,
        mobileApp: false,
      },
      sent: {
        dashboard: false,
        email: false,
        mobileApp: false,
      },
    },
    ownerStay: {
      new: {
        dashboard: false,
        email: false,
        mobileApp: false,
      },
      modify: {
        dashboard: false,
        email: false,
        mobileApp: false,
      },
    },
    preAuth: {
      success: {
        dashboard: false,
        email: false,
        mobileApp: false,
      },
      fail: {
        dashboard: false,
        email: false,
        mobileApp: false,
      },
    },
    rentalAgreementSign: {
      dashboard: false,
      email: false,
      mobileApp: false,
    },
    reservation: {
      new: {
        dashboard: false,
        email: false,
        mobileApp: false,
      },
      modify: {
        dashboard: false,
        email: false,
        mobileApp: false,
      },
      cancel: {
        dashboard: false,
        email: false,
        mobileApp: false,
      },
      pending: {
        dashboard: false,
        email: false,
        mobileApp: false,
      },
    },
    task: {
      new: {
        dashboard: false,
        email: false,
        mobileApp: false,
      },
      update: {
        dashboard: false,
        email: false,
        mobileApp: false,
      },
      start: {
        dashboard: false,
        email: false,
        mobileApp: false,
      },
      end: {
        dashboard: false,
        email: false,
        mobileApp: false,
      },
      cancel: {
        dashboard: false,
        email: false,
        mobileApp: false,
      },
    },
    thread: {
      assign: {
        dashboard: false,
        email: false,
        mobileApp: false,
      },
      unassign: {
        dashboard: false,
        email: false,
        mobileApp: false,
      },
    },
  },
};

export const isAdminAccess = {
  accessAllReservations: true,
  accessContactData: true,
  accessFinancialData: true,
  accessGuestNotes: true,
  accessTagManagement: true,
  createAutomation: true,
  createBookingEngine: true,
  createBulkEditListings: true,
  createCalendar: true,
  createChannelManager: true,
  createExpense: true,
  createFinancialReporting: true,
  createGuestInvoicing: true,
  createGuestbook: true,
  createIntegration: true,
  createListing: true,
  createLockBox: true,
  createMessage: true,
  createOwnerStatement: true,
  createOwnerStay: true,
  createReservation: true,
  createReview: true,
  createTask: true,
  deleteAutomation: true,
  deleteBookingEngine: true,
  deleteBulkEditListings: true,
  deleteCalendar: true,
  deleteChannelManager: true,
  deleteExpense: true,
  deleteFinancialReporting: true,
  deleteGuestInvoicing: true,
  deleteGuestbook: true,
  deleteIntegration: true,
  deleteListing: true,
  deleteLockBox: true,
  deleteMessage: true,
  deleteOwnerStatement: true,
  deleteOwnerStay: true,
  deleteReservation: true,
  deleteReview: true,
  deleteTask: true,
  hasAccessToNewListings: false,
  isAdmin: true,
  readAutomation: true,
  readBookingEngine: true,
  readBulkEditListings: true,
  readCalendar: true,
  readChannelManager: true,
  readCleaning: true,
  readExpense: true,
  readFinance: true,
  readFinancialReporting: true,
  readGuestCom: true,
  readGuestInvoicing: true,
  readGuestbook: true,
  readIntegration: true,
  readKeys: true,
  readListing: true,
  readLockBox: true,
  readMessage: true,
  readOwnerStatement: true,
  readOwnerStay: true,
  readPropertyReport: true,
  readRelocationTool: true,
  readReservation: true,
  readReservationTool: true,
  readReservationsOverview: true,
  readReview: true,
  readTask: true,
  readViews: true,
  template: "Admin",
  updateAutomation: true,
  updateBookingEngine: true,
  updateBulkEditListings: true,
  updateCalendar: true,
  updateChannelManager: true,
  updateCleaning: true,
  updateExpense: true,
  updateFinancialReporting: true,
  updateGuestInvoicing: true,
  updateGuestbook: true,
  updateIntegration: true,
  updateListing: true,
  updateLockBox: true,
  updateLockBoxStatus: true,
  updateMessage: true,
  updateOwnerStatement: true,
  updateOwnerStay: true,
  updateReservation: true,
  updateReview: true,
  updateTask: true,
};

export const isManagerAccess = {
  accessAllReservations: false,
  accessContactData: false,
  accessFinancialData: false,
  accessGuestNotes: false,
  accessTagManagement: false,
  createAutomation: true,
  createBookingEngine: false,
  createBulkEditListings: true,
  createCalendar: false,
  createChannelManager: true,
  createExpense: false,
  createFinancialReporting: false,
  createGuestInvoicing: true,
  createGuestbook: false,
  createIntegration: false,
  createListing: true,
  createMessage: true,
  createOwnerStatement: true,
  createOwnerStay: true,
  createReservation: false,
  createReview: true,
  createTask: true,
  deleteAutomation: true,
  deleteBookingEngine: false,
  deleteBulkEditListings: true,
  deleteCalendar: false,
  deleteChannelManager: true,
  deleteExpense: false,
  deleteFinancialReporting: false,
  deleteGuestInvoicing: true,
  deleteGuestbook: false,
  deleteIntegration: false,
  deleteListing: true,
  deleteMessage: true,
  deleteOwnerStatement: true,
  deleteOwnerStay: true,
  deleteReservation: false,
  deleteReview: true,
  deleteTask: true,
  hasAccessToNewListings: false,
  isAdmin: false,
  readAutomation: true,
  readBookingEngine: true,
  readBulkEditListings: true,
  readCalendar: true,
  readChannelManager: true,
  readExpense: false,
  readFinancialReporting: true,
  readGuestInvoicing: true,
  readGuestbook: false,
  readIntegration: false,
  readListing: true,
  readMessage: true,
  readOwnerStatement: true,
  readOwnerStay: true,
  readReservation: false,
  readReview: true,
  readTask: true,
  template: "Manager",
  updateAutomation: true,
  updateBookingEngine: true,
  updateBulkEditListings: true,
  updateCalendar: true,
  updateChannelManager: true,
  updateExpense: false,
  updateFinancialReporting: false,
  updateGuestInvoicing: true,
  updateGuestbook: false,
  updateIntegration: true,
  updateListing: true,
  updateMessage: true,
  updateOwnerStatement: true,
  updateOwnerStay: true,
  updateReservation: false,
  updateReview: true,
  updateTask: true,
};

export const isAssistantAccess = {
  accessAllReservations: false,
  accessContactData: false,
  accessFinancialData: false,
  accessGuestNotes: false,
  accessTagManagement: false,
  createAutomation: false,
  createBookingEngine: false,
  createBulkEditListings: false,
  createCalendar: false,
  createChannelManager: false,
  createExpense: false,
  createFinancialReporting: false,
  createGuestInvoicing: false,
  createGuestbook: false,
  createIntegration: false,
  createListing: false,
  createMessage: false,
  createOwnerStatement: false,
  createOwnerStay: true,
  createReservation: true,
  createReview: false,
  createTask: true,
  deleteAutomation: false,
  deleteBookingEngine: false,
  deleteBulkEditListings: false,
  deleteCalendar: false,
  deleteChannelManager: false,
  deleteExpense: false,
  deleteFinancialReporting: false,
  deleteGuestInvoicing: false,
  deleteGuestbook: false,
  deleteIntegration: false,
  deleteListing: false,
  deleteMessage: false,
  deleteOwnerStatement: false,
  deleteOwnerStay: false,
  deleteReservation: false,
  deleteReview: false,
  deleteTask: true,
  hasAccessToNewListings: false,
  isAdmin: false,
  readAutomation: true,
  readBookingEngine: true,
  readBulkEditListings: false,
  readCalendar: true,
  readChannelManager: true,
  readExpense: false,
  readFinancialReporting: false,
  readGuestInvoicing: false,
  readGuestbook: false,
  readIntegration: false,
  readListing: true,
  readMessage: true,
  readOwnerStatement: false,
  readOwnerStay: true,
  readReservation: true,
  readReview: false,
  readTask: true,
  template: "Assistant",
  updateAutomation: true,
  updateBookingEngine: false,
  updateBulkEditListings: false,
  updateCalendar: true,
  updateChannelManager: false,
  updateExpense: false,
  updateFinancialReporting: false,
  updateGuestInvoicing: false,
  updateGuestbook: false,
  updateIntegration: false,
  updateListing: true,
  updateMessage: true,
  updateOwnerStatement: false,
  updateOwnerStay: true,
  updateReservation: true,
  updateReview: false,
  updateTask: true,
};

export const isFinanceAccess = {
  accessAllReservations: false,
  accessContactData: false,
  accessFinancialData: false,
  accessGuestNotes: false,
  accessTagManagement: false,
  createAutomation: false,
  createBookingEngine: false,
  createBulkEditListings: false,
  createCalendar: false,
  createChannelManager: false,
  createExpense: false,
  createFinancialReporting: false,
  createGuestInvoicing: true,
  createGuestbook: false,
  createIntegration: false,
  createListing: false,
  createMessage: false,
  createOwnerStatement: true,
  createOwnerStay: false,
  createReservation: false,
  createReview: false,
  createTask: false,
  deleteAutomation: false,
  deleteBookingEngine: false,
  deleteBulkEditListings: false,
  deleteCalendar: false,
  deleteChannelManager: false,
  deleteExpense: false,
  deleteFinancialReporting: false,
  deleteGuestInvoicing: true,
  deleteGuestbook: false,
  deleteIntegration: false,
  deleteListing: false,
  deleteMessage: false,
  deleteOwnerStatement: true,
  deleteOwnerStay: false,
  deleteReservation: false,
  deleteReview: false,
  deleteTask: false,
  hasAccessToNewListings: false,
  isAdmin: false,
  readAutomation: false,
  readBookingEngine: false,
  readBulkEditListings: false,
  readCalendar: true,
  readChannelManager: false,
  readExpense: false,
  readFinancialReporting: true,
  readGuestInvoicing: true,
  readGuestbook: false,
  readIntegration: false,
  readListing: true,
  readMessage: true,
  readOwnerStatement: true,
  readOwnerStay: true,
  readReservation: true,
  readReview: false,
  readTask: true,
  template: "Finance",
  updateAutomation: false,
  updateBookingEngine: false,
  updateBulkEditListings: false,
  updateCalendar: false,
  updateChannelManager: false,
  updateExpense: false,
  updateFinancialReporting: false,
  updateGuestInvoicing: true,
  updateGuestbook: false,
  updateIntegration: false,
  updateListing: false,
  updateMessage: false,
  updateOwnerStatement: true,
  updateOwnerStay: false,
  updateReservation: false,
  updateReview: false,
  updateTask: true,
};

export const isPropertyOwnerAccess = {
  accessAllReservations: false,
  accessContactData: false,
  accessFinancialData: false,
  accessGuestNotes: false,
  accessTagManagement: false,
  createAutomation: false,
  createBookingEngine: false,
  createBulkEditListings: false,
  createCalendar: false,
  createChannelManager: false,
  createExpense: false,
  createFinancialReporting: false,
  createGuestInvoicing: true,
  createGuestbook: false,
  createIntegration: false,
  createListing: false,
  createMessage: false,
  createOwnerStatement: false,
  createOwnerStay: true,
  createReservation: false,
  createReview: false,
  createTask: false,
  deleteAutomation: false,
  deleteBookingEngine: false,
  deleteBulkEditListings: false,
  deleteCalendar: false,
  deleteChannelManager: false,
  deleteExpense: false,
  deleteFinancialReporting: false,
  deleteGuestInvoicing: true,
  deleteGuestbook: false,
  deleteIntegration: false,
  deleteListing: false,
  deleteMessage: false,
  deleteOwnerStatement: false,
  deleteOwnerStay: true,
  deleteReservation: false,
  deleteReview: false,
  deleteTask: false,
  hasAccessToNewListings: false,
  isAdmin: false,
  readAutomation: false,
  readBookingEngine: false,
  readBulkEditListings: false,
  readCalendar: true,
  readChannelManager: false,
  readExpense: false,
  readFinancialReporting: true,
  readGuestInvoicing: true,
  readGuestbook: false,
  readIntegration: false,
  readListing: true,
  readMessage: true,
  readOwnerStatement: true,
  readOwnerStay: true,
  readReservation: true,
  readReview: false,
  readTask: true,
  template: "Property Owner",
  updateAutomation: false,
  updateBookingEngine: false,
  updateBulkEditListings: true,
  updateCalendar: false,
  updateChannelManager: false,
  updateExpense: false,
  updateFinancialReporting: false,
  updateGuestInvoicing: true,
  updateGuestbook: false,
  updateIntegration: false,
  updateListing: false,
  updateMessage: false,
  updateOwnerStatement: false,
  updateOwnerStay: true,
  updateReservation: false,
  updateReview: false,
  updateTask: true,
};

export const isCleanerAccess = {
  accessAllReservations: false,
  accessContactData: false,
  accessFinancialData: false,
  accessGuestNotes: false,
  accessTagManagement: false,
  createAutomation: false,
  createBookingEngine: false,
  createBulkEditListings: false,
  createCalendar: false,
  createChannelManager: false,
  createExpense: false,
  createFinancialReporting: false,
  createGuestInvoicing: false,
  createGuestbook: false,
  createIntegration: false,
  createListing: false,
  createMessage: false,
  createOwnerStatement: false,
  createOwnerStay: false,
  createReservation: false,
  createReview: false,
  createTask: true,
  deleteAutomation: false,
  deleteBookingEngine: false,
  deleteBulkEditListings: false,
  deleteCalendar: false,
  deleteChannelManager: false,
  deleteExpense: false,
  deleteFinancialReporting: false,
  deleteGuestInvoicing: false,
  deleteGuestbook: false,
  deleteIntegration: false,
  deleteListing: false,
  deleteMessage: false,
  deleteOwnerStatement: false,
  deleteOwnerStay: false,
  deleteReservation: false,
  deleteReview: false,
  deleteTask: false,
  hasAccessToNewListings: false,
  isAdmin: false,
  readAutomation: false,
  readBookingEngine: false,
  readBulkEditListings: false,
  readCalendar: true,
  readChannelManager: false,
  readExpense: false,
  readFinancialReporting: false,
  readGuestInvoicing: false,
  readGuestbook: false,
  readIntegration: false,
  readListing: true,
  readMessage: true,
  readOwnerStatement: false,
  readOwnerStay: false,
  readReservation: false,
  readReview: false,
  readTask: true,
  template: "Cleaner",
  updateAutomation: false,
  updateBookingEngine: false,
  updateBulkEditListings: false,
  updateCalendar: false,
  updateChannelManager: false,
  updateExpense: false,
  updateFinancialReporting: false,
  updateGuestInvoicing: false,
  updateGuestbook: false,
  updateIntegration: false,
  updateListing: false,
  updateMessage: false,
  updateOwnerStatement: false,
  updateOwnerStay: false,
  updateReservation: false,
  updateReview: false,
  updateTask: true,
  readCleaning: true,
  updateCleaning: true,
};
