import React from 'react'
import { Button, Modal, Select } from 'antd'

import { manageToast } from '../../components/Common/ManageToast'

import { serverlessApi } from '../../utils/API'

import { STATUS_OPTIONS } from './utils'

function LockBoxStatusCell (props) {
  const {
    disabled,
    LockBoxName,
    onSuccess,
    _id,
    refetch,
    status
  } = props

  const [lockBoxStatus, setLockBoxStatus] = React.useState(status)
  const [loading, setLoading] = React.useState(false)
  const [open, setOpen] = React.useState(false)

  if (!status) {
    return '--'
  }

  const handleClose = () => setOpen(false)

  const handleValueChange = value => {
    setLockBoxStatus(value)
    setOpen(true)
  }

  const handleStatusChange = () => {
    setLoading(true)

    serverlessApi.updateLockBoxStatus(_id, {
      status: lockBoxStatus
    }).then(() => {
      setLoading(false)
      setOpen(false)
      onSuccess && onSuccess()
      refetch()
      manageToast(true, `LockBox "${LockBoxName}" updated successfully`)
    }).catch(() => {
      manageToast(false, 'Error updating lockBox status')
      setLoading(false)
      setOpen(false)
    })
  }

  return (
    <>
      <Select
        onChange={handleValueChange}
        placeholder="Select status"
        options={STATUS_OPTIONS}
        className="w-[140px]"
        disabled={disabled}
        loading={loading}
        value={status}
      />

      <Modal
        title="Confirm lockBox status change"
        open={open}
        onCancel={handleClose}
        footer={null}
      >
        Are you sure you want to change status of lockBox "{LockBoxName}"
        from "{status}" to "{lockBoxStatus}"?

        <div className="flex gap-3 justify-end items-end pt-5">
          <Button
            className="hover:!text-buttonTheme hover:!border-buttonTheme"
            onClick={handleClose}
          >
            Cancel
          </Button>

          <Button
            loading={loading}
            onClick={handleStatusChange}
            className="bg-buttonTheme hover:!bg-buttonHoverTheme self-end"
            type='primary'
          >
            Change status
          </Button>
        </div>
      </Modal>
    </>
  )
}

export default LockBoxStatusCell
