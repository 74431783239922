import { format } from 'date-fns'
import BorderColorIcon from '@mui/icons-material/BorderColor'
import DeleteIcon from '@mui/icons-material/Delete'

import { manageToast } from '../../components/Common/ManageToast'
import GenericButton from '../../components/GenericButton/GenericButton'

import { serverlessApi } from '../../utils/API'

import LockBoxStatusCell from './LockBoxStatusCell'


export const fetchCollection = (params, signal, setData, setTotal, setLoading, methodKey) => {
  return Promise.resolve()
  .then(() => setLoading(true))
  .then(() => {
    const queryParams = {
      page: params.page,
      pageSize: params.pageSize,
    }

    if (params?.city) {
      queryParams.city = params.city
    }

    if (params?.keyType) {
      queryParams.keyType = params.keyType
    }

    if (params?.status) {
      queryParams.status = params.status
    }

    if (params?.search) {
      queryParams.search = params.search
    }

    if (params.searchKey) {
      queryParams.searchKeys = params.searchKey
    }

    return queryParams
  })
  .then(params => {
    return serverlessApi[methodKey](signal ? { signal, params } : { params }).then(res => {
      setData(res?.data?.items || [])
      setTotal(res?.data?.meta?.total || 0)
      setLoading(false)
    })
  })
  .catch(e => {
    if (e?.name === 'CanceledError') {
      setLoading(true)
    } else {
      setLoading(false)
      manageToast(false, 'Server error. Something went wrong.')
    }
  })
}

// Keys Statuses 
export const STATUS_OPTIONS = [
  { label: 'Available', value: 'Available' },
  { label: 'In Use', value: 'InUse' },
  { label: 'Not Available', value: 'Not Available' },
].map(({ label, value}) => {
  let rootClassNames = 'flex items-center font-medium'
  let pinClassNames = 'inline-block w-[6px] h-[6px] rounded-[50%] mr-1'

  if (label === 'Available') {
    rootClassNames += ' text-green-600'
    pinClassNames += ' bg-green-600'
  } else if (label === 'In Use') {
    rootClassNames += ' text-orange-600'
    pinClassNames += ' bg-orange-600'
  } else {
    rootClassNames += ' text-red-600'
    pinClassNames += ' bg-red-600'
  }

  return {
    label: (
      <div className={rootClassNames}>
        <span className={pinClassNames}/>
        {label}
      </div>
    ),
    value,
    key: value,
  }
})

export const createLockBoxCollectionColumns = (
  setSelectedLockBoxId, setEditModalOpen, setDeleteModalOpen,
  setDetailsModalOpen, refetch, profile
) => ([
  {
    title: 'LockBox Name',
    dataIndex: 'LockBoxName',
    render: (_, record) => (
      <div
        onClick={() => {
          setDetailsModalOpen(true)
          setSelectedLockBoxId(record._id)
        }}
        className="font-semibold text-[#007d7d] hover:cursor-pointer"
      >
        {record?.LockBoxName || '--'}
      </div>
    ),
    width: 250
  },
  {
    title: 'Property',
    dataIndex: 'PropertyID',
    render: (_, record) => {
      return record.property.internalListingName
    },
    align: 'center',
    width: 220
  },
  {
    title: 'Code',
    dataIndex: 'Code',
    render: (_, record) => record?.Code || '--',
    align: 'center',
    width: 140
  },
  {
    title: 'Lockbox Type',
    dataIndex: 'LockboxType',
    render: (_, record) => record?.LockboxType || '--',
    align: 'center',
    width: 140
  },
  {
    title: 'Status',
    dataIndex: 'status',
    render: (_, record) => (
      <LockBoxStatusCell
        disabled={!(profile?.updateLockBoxStatus || profile?.isAdmin)}
        refetch={refetch}
        {...record}
      />
    ),
    align: 'center',
    width: 160
  },
  {
    title: 'Updated At',
    dataIndex: 'updatedAt',
    render: (_, record) => record?.updatedAt
      ? format(new Date(record.updatedAt), 'dd/MM/yyyy')
      : '--',
    align: 'center',
    width: 150
  },
  (profile?.isAdmin || profile?.updateLockBox || profile?.deleteLockBox) && {
    title: 'Actions',
    dataIndex: '_id',
    render: (_, record) => (
      <div className='flex justify-center gap-2'>
        {(profile?.isAdmin || profile?.updateLockBox) && (
          <GenericButton
            label="Edit"
            className={`bg-labelGreen text-themeGreen border-themeGreen 
            hover:bg-white hover:!text-themeGreen hover:!border-themeGreen`}
            iconLeftAlign={true}
            icon={<BorderColorIcon style={{ fontSize: 15 }} />}
            onClick={() => {
              setEditModalOpen(true)
              setSelectedLockBoxId(record._id)
            }}
          />
        )}

        {(profile?.isAdmin || profile?.deleteLockBox) && (
          <GenericButton
            label="Delete"
            className={`bg-red-100 text-red-500 border-red-500 hover:bg-white 
            hover:!text-red-500 hover:!border-red-500`}
            iconLeftAlign={true}
            icon={<DeleteIcon style={{ fontSize: 15 }} />}
            onClick={() => {
              setDeleteModalOpen(true)
              setSelectedLockBoxId(record._id)
            }}
          />
        )}
      </div>
    ),
    align: 'center',
    width: 200
  }
].filter(Boolean))

export const createKeysCollectionColumns = () => ([
  {
    title: 'Key Name',
    dataIndex: 'KeyName',
    render: (_, record) => record?.KeyName || '--',
    className: 'font-semibold text-[#007d7d]'
  },
  {
    title: 'Last Movement',
    dataIndex: 'LastMovement',
    render: (_, record) => record?.LastMovement
      ? format(new Date(record.LastMovement), 'dd/MM/yyyy HH:mm')
      : '--',
    align: 'center'
  },
  {
    title: 'Current Status',
    dataIndex: 'CurrentStatus',
    render: (_, record) => record?.CurrentStatus || '--',
    align: 'center'
  },
  {
    title: 'Current User Name',
    dataIndex: 'CurrentUserName',
    render: (_, record) => record?.CurrentUserName || '--',
    align: 'center'
  }
])
