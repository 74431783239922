/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import { Modal } from 'antd'

import { serverlessApi } from '../../../utils/API'
import { manageToast } from '../../../components/Common/ManageToast'

import LockBoxForm from './Form'

function LockBoxModal (props) {
  const {
    form,
    formName,
    handleClose,
    handleSubmit,
    hideStatusField,
    initialValues,
    open,
    loading,
    lockBox,
    selectedProperty,
    setSelectedProperty,
    submitButtonLabel,
    title
  } = props

  const [properties, setProperties] = React.useState([])
  const [propertiesLoading, setPropertiesLoading] = React.useState(false)

  const fetchProperties = () => {
    setPropertiesLoading(true)

    serverlessApi.getPropertiesForReservation().then(res => {
      if (res.status === 200) {
        const properties = res?.data.data || []
        const normalizedProperties = properties.filter(item => {
          return !item?.internalListingName?.toLowerCase()?.includes('communal area')
        }).map(item => ({
          label: item.internalListingName,
          value: item.id,
          key: item._id,
          data: { ...item }
        }))

        setProperties(normalizedProperties)
        setPropertiesLoading(false)
      }
    }).catch(() => {
      manageToast('Error fetching properties.', false)
      setPropertiesLoading(false)
    })
  }

  React.useEffect(() => {
    if (open) {
      fetchProperties()
      form.setFieldsValue(initialValues)
    }
  }, [open, initialValues, form])

  React.useEffect(() => {
    if (open && lockBox) {
      setSelectedProperty(lockBox?.property)
    }
  }, [lockBox, open])

  return (
    <Modal
      title={title}
      open={open}
      footer={null}
      onCancel={handleClose}
    >
      <LockBoxForm
        form={form}
        formName={formName}
        handleCancel={handleClose}
        handleSubmit={handleSubmit}
        hideStatusField={hideStatusField}
        loading={loading}
        properties={properties}
        propertiesLoading={propertiesLoading}
        selectedProperty={selectedProperty}
        setSelectedProperty={setSelectedProperty}
        submitButtonLabel={submitButtonLabel}
      />
    </Modal>
  )
}

export default LockBoxModal
