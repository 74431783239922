import React from 'react'
import ChartsEmbedSDK from '@mongodb-js/charts-embed-dom'

import {
  MONGO_DB_EMBED_CHART_SDK_BASE_URL,
  MONGO_DB_EMBED_CHART_SDK_DASHBOARD_ID,
} from '../../../constants/finance'

function FinanceDashboard () {
  const sdk = new ChartsEmbedSDK({
    baseUrl: MONGO_DB_EMBED_CHART_SDK_BASE_URL,
  })

  const dashboardInstance = sdk.createDashboard({
    dashboardId: MONGO_DB_EMBED_CHART_SDK_DASHBOARD_ID,
    background: 'rgb(255, 255, 255)',
  })

  React.useEffect(() => {
    dashboardInstance.render(document.getElementById('dashboard')).catch(() => {
      console.log('Chart failed to initialise')
    })
  }, [dashboardInstance])

  return (
    <div id="dashboard" className="h-[1000px]"/>
  )
}

export default FinanceDashboard
