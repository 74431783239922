import React from 'react'
import { Form } from 'antd'

import { manageToast } from '../../../components/Common/ManageToast'
import { serverlessApi } from '../../../utils/API'

import LockBoxModal from './Modal'

const initialValues = {
  propertyId: undefined,
  unitId: undefined,
  lockboxName: undefined,
  code: undefined,
  status: undefined,
  LockboxType: undefined
}

function LockBoxCreateModal (props) {
  const { onClose, open, triggerLockBoxCollectionRefetch } = props

  const [form] = Form.useForm()

  const [loading, setLoading] = React.useState(false)
  const [selectedProperty, setSelectedProperty] = React.useState(undefined)

  const handleClose = () => {
    form.resetFields()
    setSelectedProperty(undefined)
    onClose()
  }

  const handleLockBoxCreate = values => {
    return Promise.resolve()
      .then(() => setLoading(true))
      .then(() => {
        const payload = {
          ...values,
          LockboxType: values.LockboxType || '--'
        }
        return serverlessApi.createLockBox(payload).then(res => {
          triggerLockBoxCollectionRefetch()
          setLoading(false)
          manageToast(true, res.data.message)
          handleClose()
        })
      })
      .catch(() => setLoading(false))
  }

  return (
    <LockBoxModal
      form={form}
      formName="lockbox-create"
      handleClose={handleClose}
      handleSubmit={handleLockBoxCreate}
      open={open}
      initialValues={initialValues}
      submitButtonLabel="Create"
      loading={loading}
      selectedProperty={selectedProperty}
      setSelectedProperty={setSelectedProperty}
      title="Add New LockBox"
    />
  )
}

export default LockBoxCreateModal
