import React from 'react'
import { Form } from 'antd'

import { manageToast } from '../../../components/Common/ManageToast'
import { serverlessApi } from '../../../utils/API'

import LockBoxModal from './Modal'

function LockBoxUpdateModal (props) {
  const { lockBox, onClose, open, triggerLockBoxCollectionRefetch } = props

  const [form] = Form.useForm()

  const [loading, setLoading] = React.useState(false)
  const [selectedProperty, setSelectedProperty] = React.useState(undefined)

  const initialValues = React.useMemo(() => {
    if (!lockBox) {
      return {}
    }

    return {
      propertyId: Number(lockBox.PropertyID),
      lockboxName: lockBox.LockBoxName,
      code: lockBox.Code,
      unitId: lockBox?.UnitID ? Number(lockBox.UnitID) : undefined,
      LockboxType: lockBox.LockboxType // Match the form field name
    }
  }, [lockBox])

  const handleClose = () => {
    form.resetFields()
    setSelectedProperty(undefined)
    onClose()
  }

  const handleLockBoxUpdate = values => {
    return Promise.resolve()
    .then(() => setLoading(true))
    .then(() => {
      const payload = {
        ...values,
        unitId: values?.unitId || null,
        LockboxType: values.LockboxType // Send the field directly to API
      }
      return serverlessApi.updateLockBox(lockBox._id, payload).then(res => {
        triggerLockBoxCollectionRefetch()
        setLoading(false)
        manageToast(true, res.data.message)
        handleClose()
      })
    })
    .catch(() => setLoading(false))
  }

  return (
    <LockBoxModal
      form={form}
      formName="lockbox-update"
      handleClose={handleClose}
      handleSubmit={handleLockBoxUpdate}
      hideStatusField
      initialValues={initialValues}
      loading={loading}
      lockBox={lockBox}
      open={open}
      selectedProperty={selectedProperty}
      setSelectedProperty={setSelectedProperty}
      submitButtonLabel="Update"
      title="Update LockBox"
    />
  )
}

export default React.memo(LockBoxUpdateModal)
