import React from 'react'
import { Button, Form, Input, Radio, Select } from 'antd'

import { STATUS_OPTIONS } from '../utils'

import './styles.css'

const requiredRules = [{
  required: true,
  message: 'This field is required'
}]

function LockBoxForm (props) {
  const {
    form,
    formName,
    handleCancel,
    handleSubmit,
    hideStatusField,
    properties,
    selectedProperty,
    setSelectedProperty,
    submitButtonLabel,
    propertiesLoading,
    loading,
    initialValues
  } = props

  const showUnitsField = React.useMemo(() => {
    if (!selectedProperty) {
      return false
    }

    const groupedPropertyCustomField = selectedProperty?.customFieldValues?.find(item =>
      item?.customField?.name === 'grouped_property'
    )

    return selectedProperty.listingUnits?.length > 1 &&
      groupedPropertyCustomField?.value === 'Yes'
  }, [selectedProperty])

  return (
    <Form
      autoComplete="off"
      form={form}
      layout="vertical"
      name={formName}
      onFinish={handleSubmit}
      initialValues={initialValues}
    >
      <Form.Item
        name="propertyId"
        label="Property"
        rules={requiredRules}
        className="!mb-3"
      >
        <Select
          loading={propertiesLoading}
          placeholder="Select property"
          optionFilterProp="label"
          options={properties}
          onChange={(_, option) => {
            setSelectedProperty(option?.data)
            form.setFieldValue('unitId', undefined)
          }}
          showSearch
          allowClear
        />
      </Form.Item>

      {showUnitsField && (
        <Form.Item
          name="unitId"
          label="Property unit"
          messageVariables={{ name: 'Listing unit' }}
          rules={requiredRules}
          className="!mb-3"
        >
          <Select
            allowClear
            placeholder="Select Unit"
            options={selectedProperty.listingUnits.map(unit => ({
              label: unit.name,
              value: unit.id
            }))}
            className="w-full"
          />
        </Form.Item>
      )}

      <Form.Item
        name="lockboxName"
        label="Lockbox Name"
        rules={requiredRules}
        className="!mb-3"
      >
        <Input placeholder="Enter lockbox name" />
      </Form.Item>

      <Form.Item
        name="code"
        label="Code"
        rules={requiredRules}
        className="!mb-3"
      >
        <Input placeholder="Enter lockbox code" />
      </Form.Item>

      {!hideStatusField && (
        <Form.Item
          name="status"
          label="Status"
          rules={requiredRules}
          className="!mb-3"
        >
          <Select
            placeholder="Select status"
            options={STATUS_OPTIONS}
          />
        </Form.Item>
      )}

      <Form.Item
        name="LockboxType"
        label="Lockbox Type"
        className="!mb-3"
      >
        <Radio.Group>
          <Radio value="primary">Primary Lockbox</Radio>
          <Radio value="secondary">Secondary Lockbox</Radio>
        </Radio.Group>
      </Form.Item>

      <div className="flex gap-3 justify-end items-end pt-5">
        <Button
          className="hover:!text-buttonTheme hover:!border-buttonTheme"
          onClick={handleCancel}
        >
          Cancel
        </Button>

        <Form.Item
          label={null}
          className="!mb-0"
        >
          <Button
            loading={loading}
            className="bg-buttonTheme hover:!bg-buttonHoverTheme self-end"
            htmlType="submit"
            type='primary'
          >
            {submitButtonLabel}
          </Button>
        </Form.Item>
      </div>
    </Form>
  )
}

export default LockBoxForm
