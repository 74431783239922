/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import { Table } from 'antd'

import Loading from '../../../components/Loaders/Loading'
import { paginationItemRender } from '../../../utils/table'
import { AppContext } from '../../../AppContext'

import LockBoxDeleteModal from './DeleteModal'
import LockBoxDetailsModal from './DetailsModal'
import LockBoxUpdateModal from './UpdateModal'

import { createLockBoxCollectionColumns, fetchCollection } from '../utils'

function LockBoxSection (props) {
  const {
    city,
    pageSize,
    refetchLockBoxCollection,
    search,
    setPageSize,
    status,
    triggerLockBoxCollectionRefetch
  } = props

  const profile = React.useContext(AppContext).profile || {}

  const [loading, setLoading] = React.useState(true)
  const [page, setPage] = React.useState(1)
  const [total, setTotal] = React.useState(0)
  const [data, setData] = React.useState([])
  const [selectedLockBoxId, setSelectedLockBoxId] = React.useState('')
  const [deleteModalOpen, setDeleteModalOpen] = React.useState(false)
  const [editModalOpen, setEditModalOpen] = React.useState(false)
  const [detailsModalOpen, setDetailsModalOpen] = React.useState(false)

  const selectedLockBox = React.useMemo(
    () => data.find(item => item._id === selectedLockBoxId),
    [data, selectedLockBoxId]
  )

  const fetchLockBoxCollection = signal => {
    fetchCollection(
      { status, city, page, pageSize, search },
      signal,
      setData,
      setTotal,
      setLoading,
      'getLockBoxCollection'
    )
  }

  React.useEffect(() => {
    const controller = new AbortController()

    fetchLockBoxCollection(controller.signal)

    return () => {
      controller.abort()
    }
  }, [
    city,
    page,
    pageSize,
    refetchLockBoxCollection,
    search,
    status
  ])

  return (
    <div className="pt-7">
      {loading
        ? <Loading />
        : (
          <div>
            <Table
              loading={loading}
              dataSource={data}
              columns={createLockBoxCollectionColumns(
                setSelectedLockBoxId, setEditModalOpen, setDeleteModalOpen,
                setDetailsModalOpen,triggerLockBoxCollectionRefetch, profile
              )}
              scroll={{ x: 1000 }}
              sticky={{ offsetHeader: 45 }}
              pagination={{
                current: page,
                pageSize,
                total,
                showSizeChanger: true,
                itemRender: paginationItemRender,
                onChange: (nextPage, nextLimit) => {
                  if (nextPage !== page) {
                    setPage(nextPage)
                  }

                  if (nextLimit !== pageSize) {
                    setPage(1)
                    setPageSize(nextLimit)
                  }
                }
              }}
              rowKey="_id"
            />

            {(profile?.isAdmin || profile?.updateLockBox) && (
              <LockBoxUpdateModal
                open={!!selectedLockBoxId && !!selectedLockBox && editModalOpen}
                onClose={() => {
                  setSelectedLockBoxId('')
                  setEditModalOpen(false)
                }}
                triggerLockBoxCollectionRefetch={triggerLockBoxCollectionRefetch}
                lockBox={selectedLockBox}
              />
            )}

            {(profile?.isAdmin || profile?.deleteLockBox) && (
              <LockBoxDeleteModal
                open={!!selectedLockBoxId && !!selectedLockBox && deleteModalOpen}
                onClose={() => {
                  if (!detailsModalOpen) {
                    setSelectedLockBoxId('')
                  }
                  setDeleteModalOpen(false)
                }}
                triggerLockBoxCollectionRefetch={triggerLockBoxCollectionRefetch}
                lockBox={selectedLockBox}
              />
            )}

            <LockBoxDetailsModal
              canUpdate={profile?.isAdmin || profile?.updateLockBoxStatus}
              canDelete={profile?.isAdmin || profile?.deleteLockBox}
              open={!!selectedLockBoxId && !!selectedLockBox && detailsModalOpen}
              onClose={() => {
                setSelectedLockBoxId('')
                setDetailsModalOpen(false)
              }}
              handleDeleteModalOpen={() => setDeleteModalOpen(true)}
              triggerLockBoxCollectionRefetch={triggerLockBoxCollectionRefetch}
              lockBox={selectedLockBox}
            />
          </div>
        )}
    </div>
  )
}

export default LockBoxSection
