import axios from 'axios'
import { SERVER_URL, SERVERLESS_URL } from '../constants/serverUrl'
import { logOut } from './constants'

export const apiInstance = axios.create({
  baseURL: SERVER_URL,
  headers: {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${localStorage.getItem('authorizationToken')}`
  }
})

export const serverlessApiInstance = axios.create({
  baseURL: SERVERLESS_URL,
  headers: {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${localStorage.getItem('authorizationToken')}`
  }
})

apiInstance.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    if (error?.response?.status === 401) {
      if (!error.request?.responseURL?.includes('auth/login')) {
        logOut()
        return (window.location.href = '/')
      }
    }
    return Promise.reject(error)
  }
)

serverlessApiInstance.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    if (error?.response?.status === 401) {
      if (!error.request?.responseURL?.includes('auth/login')) {
        logOut()
        return (window.location.href = '/')
      }
    }
    return Promise.reject(error)
  },
)

export const serverlessApi = {
  // cleanings
  // getCleanings: (params) => {
  //   const _params = new URLSearchParams(params)
  //   return serverlessApiInstance.get(`/cleaning?${_params.toString()}`)
  // },
  // createCleaning: (url, data) =>
  //   serverlessApiInstance.post(`/cleaning?${url}`, { ...data }),
  // updateCleaning: (id, userName, data) =>
  //   serverlessApiInstance.put(`/cleaning/${id}?userName=${userName}`, { ...data }), // new
  // updateCleaningStatus: (id, userName, data) =>
  //   serverlessApiInstance.put(`/cleaning/status/${id}?userName=${userName}`, { ...data }),
  // putCleaningComment: (id, userName, data) =>
  //   serverlessApiInstance.put(`/cleaning/comment/${id}?userName=${userName}`, { ...data }),
  // syncCleanings: () =>
  //   serverlessApiInstance.post('/cleaning/sync'),
  // getAllCleaners: () =>
  //   serverlessApiInstance.get('/cleaning/cleaners/all'), // new
  // getCleaners: () =>
  //   serverlessApiInstance.get('/cleaning/cleaners'), // new
  // createCleaner: (data) =>
  //   serverlessApiInstance.post('/cleaning/cleaner', { ...data }), // new
  // updateCleaner: (id, data) =>
  //   serverlessApiInstance.put(`/cleaning/cleaner/name/${id}`, { ...data }), // new
  // getListingNames: () =>
  //   serverlessApiInstance.get('/property/listing-names'),
  // deleteRecurringCleaning: (id, userName, property) =>
  //   serverlessApiInstance.delete(`/cleaning/recurring/${id}?userName=${userName}&property=${property}`),
  // deleteCleaning: (id, userName) =>
  //   serverlessApiInstance.delete(`/cleaning/${id}?userName=${userName}`),
  // property report
  getPropertyReport: (url, config) =>
    serverlessApiInstance.get(`property/report${url}`, config),
  // actions
  // getActions: (url) =>
  //   serverlessApiInstance.get(`/notification?${url}`),
  // views
  // getPropertyAvailabilities: (url) =>
  //   serverlessApiInstance.get(`/property/available?${url}`),
  // keys
  getKeysCollection: config =>
    serverlessApiInstance.get('/keys', { ...config }),
  getLockBoxCollection: config =>
    serverlessApiInstance.get('/lockbox', { ...config }),
  createLockBox: (data) =>
    serverlessApiInstance.post('/lockbox', { ...data }),
  updateLockBox: (id, data) =>
    serverlessApiInstance.put(`/lockbox/${id}`, { ...data }),
  updateLockBoxStatus: (id, data) =>
    serverlessApiInstance.put(`/lockbox/${id}/status`, { ...data }),
  deleteLockBox: (id) =>
    serverlessApiInstance.delete(`/lockbox/${id}`),
  // relocation tool
  getReservationTool: (url) =>
    serverlessApiInstance.get(`/reservation/tool?${url}`),
  getRelocationTool: (url) =>
    serverlessApiInstance.get(`/reservation/relocation?${url}`),
  // properties
  getPropertiesForReservation: () =>
    serverlessApiInstance.get('reservation/properties-for-reservation'),
  // users
  createUser: (data) =>
    serverlessApiInstance.post('user', data),
  updateUser: (id, data) =>
    serverlessApiInstance.put(`/user/${id}`, data),
}

export const routes = {
  LOGIN: '/auth/login',
  PROPERTIES_LIST: '/properties/propertiesList',
  PROPERT_QUICK_EDIT: '/properties/propertyQuickEdits/',
  PROPERTIES: '/properties/property/',
  CREATE_TAGS: '/properties/createTag',
  GET_TAGS: '/properties/getTags',
  PROPERTY_TYPES: '/properties/get-propertyType',
  COUNTRIES_LIST: '/properties/countryList',
  CITIES_LIST: '/properties/cityList/',
  GET_AMENITIES: '/properties/get-amunity',
  CREATE_CANCELATION_POLICY: '/properties/createCancellationPolicy',
  GET_CANCELATION_POLICIES: '/properties/getCancellationPolicies',
  CREATE_PROPERTY: '/properties/create-property',
  GET_IMAGE_TYPES: '/properties/imageTypes',
  EXISTING_RESERVATIONS: '/reservation/listing/',
  SYNC_PROPERTIES: '/ru/get-properties',
  SYNC_ALL: '/sync/all',
  SYNC_CLEANINGS: '/api/cleaning/sync',
  RESERVATION_PROPERTIES: '/reservation/get-propertyForReservation',
  BULK_UPDATE_BUILDINGS: '/properties/bulkUpdateBuildings',
  RESERVATION_TOOL: '/ru/reservation-tool',
  SYNC_VIEWS: '/api/views/sync',
  SYNC_RESERVATION_REPORT: '/api/views/sync-reservations-report',
  RESERVATION_TOOL_API: '/reservation/tool',
  RESERVATION_RELOCATION: '/reservation/relocation',
  GET_LISTING_NAMES: '/properties/listingNames',
  CREATE_CLEANING: '/api/cleaning/createCleaning',
  CREATE_CLEANER: '/api/cleaning/create-cleaner',
  GET_CLEANER: '/api/cleaning/cleaners',
  SYNC_CHECKIN_INSTRUCTIONS: '/api/syncCheckinInstructions',
  UPDATE_CALENDARS: '/updateCalendars',
  PROPERTY_REPORT: '/properties/report',
  RESERVATIONS_OVERVIEW: '/reservation/overview',
  CHANGE_PASSWORD: '/auth/changePassword',
  GUEST_COM: '/properties/propertyDetails',
  GUEST_COM_HOSTAWAY: '/properties/hostawayPropertyDetails',
  EXCHANGE_RATES: '/exchangerate',
  PROPERTIES_BY_POSTAL_CODE: 'properties/byPostalCode',
}
